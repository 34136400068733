"use strict";
import { useMenuContent } from "components/NavBar/CompanyMenu/Content";
import { MenuLink } from "components/NavBar/CompanyMenu/MenuDropdown";
import { NavDropdown } from "components/NavBar/NavDropdown";
import { getSettingsViewIndex } from "components/NavBar/PreferencesMenu";
import { CurrencySettings } from "components/NavBar/PreferencesMenu/Currency";
import { LanguageSettings } from "components/NavBar/PreferencesMenu/Language";
import { PreferenceSettings } from "components/NavBar/PreferencesMenu/Preferences";
import { PreferencesView } from "components/NavBar/PreferencesMenu/shared";
import { useTabsContent } from "components/NavBar/Tabs/TabsContent";
import styled, { useTheme } from "lib/styled-components";
import { Socials } from "pages/Landing/sections/Footer";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";
import { Accordion, AnimateTransition, Flex, Square, Text } from "ui/src";
import { useTranslation } from "uniswap/src/i18n";
const StyledMenuLink = styled(MenuLink)`
  color: ${({ theme }) => theme.neutral2} !important;
  &:hover {
    color: ${({ theme }) => theme.neutral2} !important;
  }
`;
function MenuSection({
  title,
  children,
  collapsible = true
}) {
  const theme = useTheme();
  return <Accordion.Item value={title} disabled={!collapsible}><Flex gap="10px">
    <Accordion.Trigger flexDirection="row" p="0" gap="4px">{({ open }) => <>
      <Text variant="body1" color="$neutral1">{title}</Text>
      {collapsible && <Square animation="200ms" rotate={open ? "-180deg" : "0deg"}><ChevronDown size="20px" color={theme.neutral2} /></Square>}
    </>}</Accordion.Trigger>
    <Accordion.Content p="0" forceMount={!collapsible || void 0}><Flex gap="10px">{children}</Flex></Accordion.Content>
  </Flex></Accordion.Item>;
}
export function MobileMenuDrawer({ isOpen, closeMenu }) {
  const [openSections, setOpenSections] = useState();
  const [settingsView, setSettingsView] = useState(PreferencesView.SETTINGS);
  const dropdownRef = useRef(null);
  const changeView = useCallback(
    (view) => {
      setSettingsView(view);
      if (dropdownRef?.current) {
        dropdownRef.current.scroll({
          top: 0
        });
      }
    },
    [setSettingsView, dropdownRef]
  );
  const onExitPreferencesMenu = useCallback(() => changeView(PreferencesView.SETTINGS), [changeView]);
  const { t } = useTranslation();
  const tabsContent = useTabsContent({ includeNftsLink: true });
  const menuContent = useMenuContent();
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setOpenSections([]), 300);
    }
  }, [isOpen]);
  return <NavDropdown dropdownRef={dropdownRef} isOpen={isOpen} dataTestId="company-menu-mobile-drawer"><Flex pt="$spacing12" pb="$spacing32" px="$spacing24"><AnimateTransition
    currentIndex={getSettingsViewIndex(settingsView)}
    animationType={settingsView === PreferencesView.SETTINGS ? "forward" : "backward"}
  >
    <Accordion
      overflow="hidden"
      width="100%"
      type="multiple"
      value={openSections}
      onValueChange={setOpenSections}
    ><Flex gap="$spacing24">
      <MenuSection title={t("common.app")} collapsible={false}>{tabsContent.map((tab, index) => <StyledMenuLink
        key={`${tab.title}_${index}}`}
        label={tab.title}
        href={tab.href}
        internal
        closeMenu={closeMenu}
      />)}</MenuSection>
      {menuContent.map((sectionContent, index) => <MenuSection key={`${sectionContent.title}_${index}`} title={sectionContent.title}>{sectionContent.items.map(({ label, href, internal }, index2) => <StyledMenuLink
        key={`${label}_${index2}}`}
        label={label}
        href={href}
        internal={internal}
        closeMenu={closeMenu}
      />)}</MenuSection>)}
      <MenuSection title={t("common.displaySettings")}><PreferenceSettings showHeader={false} setSettingsView={changeView} /></MenuSection>
      {
        /* <DownloadApp onClick={closeMenu} /> */
      }
      <Socials iconSize="25px" />
    </Flex></Accordion>
    <LanguageSettings onExitMenu={onExitPreferencesMenu} />
    <CurrencySettings onExitMenu={onExitPreferencesMenu} />
  </AnimateTransition></Flex></NavDropdown>;
}
