"use strict";
import { CONNECTION, useRecentConnectorId } from "components/Web3Provider/constants";
import { useConnect } from "hooks/useConnect";
import { useCallback, useMemo } from "react";
import { isMobileWeb } from "utilities/src/platform";
const SHOULD_THROW = { shouldThrow: true };
function getConnectorWithId(connectors, id, options) {
  const connector = connectors.find((c) => c.id === id);
  if (!connector && options?.shouldThrow) {
    throw new Error(`Expected connector ${id} missing from wagmi context.`);
  }
  return connector;
}
export function useConnectorWithId(id, options) {
  const { connectors } = useConnect();
  return useMemo(
    () => options?.shouldThrow ? getConnectorWithId(connectors, id, options) : getConnectorWithId(connectors, id),
    [connectors, id, options]
  );
}
function getInjectedConnectors(connectors, excludeUniswapConnections) {
  let isCoinbaseWalletBrowser = false;
  const injectedConnectors = connectors.filter((c) => {
    if (c.id === CONNECTION.COINBASE_RDNS) {
      if (isMobileWeb) {
        isCoinbaseWalletBrowser = true;
      }
      return false;
    }
    if (c.id === CONNECTION.UNISWAP_EXTENSION_RDNS && excludeUniswapConnections) {
      return false;
    }
    return c.type === CONNECTION.INJECTED_CONNECTOR_TYPE && c.id !== CONNECTION.INJECTED_CONNECTOR_ID;
  });
  const fallbackInjector = getConnectorWithId(connectors, CONNECTION.INJECTED_CONNECTOR_ID, { shouldThrow: true });
  if (!injectedConnectors.length && Boolean(window.ethereum)) {
    return { injectedConnectors: [fallbackInjector], isCoinbaseWalletBrowser };
  }
  return { injectedConnectors, isCoinbaseWalletBrowser };
}
export function useOrderedConnections(excludeUniswapConnections) {
  const { connectors } = useConnect();
  const recentConnectorId = useRecentConnectorId();
  const sortByRecent = useCallback(
    (a, b) => {
      if (a.id === recentConnectorId) {
        return -1;
      } else if (b.id === recentConnectorId) {
        return 1;
      } else {
        return 0;
      }
    },
    [recentConnectorId]
  );
  return useMemo(() => {
    const { injectedConnectors: injectedConnectorsBase, isCoinbaseWalletBrowser } = getInjectedConnectors(
      connectors,
      excludeUniswapConnections
    );
    const injectedConnectors = injectedConnectorsBase.map((c) => ({ ...c, isInjected: true }));
    const coinbaseSdkConnector = getConnectorWithId(connectors, CONNECTION.COINBASE_SDK_CONNECTOR_ID, SHOULD_THROW);
    const walletConnectConnector = getConnectorWithId(connectors, CONNECTION.WALLET_CONNECT_CONNECTOR_ID, SHOULD_THROW);
    const uxuyWalletConnector = getConnectorWithId(connectors, CONNECTION.UXUY_WALLET, SHOULD_THROW);
    const uniswapWalletConnectConnector = getConnectorWithId(
      connectors,
      CONNECTION.UNISWAP_WALLET_CONNECT_CONNECTOR_ID,
      SHOULD_THROW
    );
    if (!coinbaseSdkConnector || !walletConnectConnector || !uniswapWalletConnectConnector) {
      throw new Error("Expected connector(s) missing from wagmi context.");
    }
    const orderedConnectors = [];
    orderedConnectors.push(uxuyWalletConnector);
    orderedConnectors.sort(sortByRecent);
    return orderedConnectors;
  }, [connectors, excludeUniswapConnections, sortByRecent]);
}
export var ExtensionRequestMethods = /* @__PURE__ */ ((ExtensionRequestMethods2) => {
  ExtensionRequestMethods2["OPEN_SIDEBAR"] = "uniswap_openSidebar";
  return ExtensionRequestMethods2;
})(ExtensionRequestMethods || {});
const ExtensionRequestArguments = {
  ["uniswap_openSidebar" /* OPEN_SIDEBAR */]: ["Tokens", "Activity"]
};
export function useUniswapExtensionConnector() {
  const connector = useConnectorWithId(CONNECTION.UNISWAP_EXTENSION_RDNS);
  const extensionRequest = useCallback(
    async (method, arg) => {
      const provider = await connector?.getProvider();
      if (!provider.request) {
        return;
      }
      await provider.request({
        method,
        params: [arg]
      });
    },
    [connector]
  );
  return useMemo(() => {
    return connector ? { ...connector, extensionRequest } : void 0;
  }, [connector, extensionRequest]);
}
