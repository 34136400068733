"use strict";
import GNOSIS_ICON from "assets/images/gnosis.png";
import COINBASE_ICON from "assets/wallets/coinbase-icon.svg";
import METAMASK_ICON from "assets/wallets/metamask-icon.svg";
import UNIWALLET_ICON from "assets/wallets/uniswap-wallet-icon.png";
import WALLET_CONNECT_ICON from "assets/wallets/walletconnect-icon.svg";
import UXUYWALLET_ICON from "assets/wallets/uxuy-wallet-icon.svg";
import { atomWithStorage, useAtomValue } from "jotai/utils";
export const CONNECTION = {
  WALLET_CONNECT_CONNECTOR_ID: "walletConnect",
  UNISWAP_WALLET_CONNECT_CONNECTOR_ID: "uniswapWalletConnect",
  INJECTED_CONNECTOR_ID: "injected",
  INJECTED_CONNECTOR_TYPE: "injected",
  COINBASE_SDK_CONNECTOR_ID: "coinbaseWalletSDK",
  COINBASE_RDNS: "com.coinbase.wallet",
  METAMASK_RDNS: "io.metamask",
  UXUY_WALLET: "uxuyWallet",
  UNISWAP_EXTENSION_RDNS: "org.uniswap.app",
  SAFE_CONNECTOR_ID: "safe"
};
export const CONNECTOR_ICON_OVERRIDE_MAP = {
  [CONNECTION.METAMASK_RDNS]: METAMASK_ICON,
  [CONNECTION.UXUY_WALLET]: UXUYWALLET_ICON,
  [CONNECTION.UNISWAP_WALLET_CONNECT_CONNECTOR_ID]: UNIWALLET_ICON,
  [CONNECTION.COINBASE_SDK_CONNECTOR_ID]: COINBASE_ICON,
  [CONNECTION.WALLET_CONNECT_CONNECTOR_ID]: WALLET_CONNECT_ICON,
  [CONNECTION.SAFE_CONNECTOR_ID]: GNOSIS_ICON
};
export const recentConnectorIdAtom = atomWithStorage("recentConnectorId", void 0);
export function useRecentConnectorId() {
  return useAtomValue(recentConnectorIdAtom);
}
