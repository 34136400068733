"use strict";
import { WalletTgSdk } from "@uxuycom/web3-tg-sdk";
import {
  ProviderNotFoundError,
  ChainNotConfiguredError,
  createConnector
} from "@wagmi/core";
import {
  SwitchChainError,
  UserRejectedRequestError,
  getAddress,
  numberToHex
} from "viem";
const type = "uxuy";
export function uxuy(parameters = {}) {
  const { shimDisconnect = false } = parameters;
  let provider_;
  let disconnect;
  let accountsChanged;
  let chainChanged;
  return createConnector((config) => ({
    id: "uxuyWallet",
    name: "UXUY Wallet",
    type: "uxuy",
    async connect() {
      const provider = await this.getProvider();
      if (!provider)
        throw new ProviderNotFoundError();
      const chainId = await this.getChainId();
      console.log("chain id:" + chainId);
      const accounts = await this.getAccounts();
      if (!disconnect) {
        disconnect = this.onDisconnect.bind(this);
        provider.on("disconnect", disconnect);
      }
      if (!chainChanged) {
        chainChanged = this.onChainChanged.bind(this);
        provider.on("chainChanged", chainChanged);
      }
      let currentChainId = await this.getChainId();
      if (chainId && currentChainId !== chainId) {
        const chain = await this.switchChain({ chainId }).catch((error) => {
          return { id: currentChainId };
        });
        currentChainId = chain?.id ?? currentChainId;
      }
      if (shimDisconnect)
        await config.storage?.removeItem("uxuy.disconnected");
      return { accounts, chainId };
    },
    async disconnect() {
      const provider = await this.getProvider();
      try {
        await provider?.disconnect();
      } catch (error) {
        if (!/No matching key/i.test(error.message))
          throw error;
      } finally {
        if (chainChanged) {
          provider?.removeListener("chainChanged", chainChanged);
          chainChanged = void 0;
        }
        if (disconnect) {
          provider?.removeListener("disconnect", disconnect);
          disconnect = void 0;
        }
        if (accountsChanged) {
          provider?.removeListener("accountsChanged", accountsChanged);
          accountsChanged = void 0;
        }
      }
    },
    async getAccounts() {
      const provider = await this.getProvider();
      if (!provider)
        throw new ProviderNotFoundError();
      const accounts = await provider.request({ method: "eth_requestAccounts" });
      if (!accounts || accounts.length === 0) {
        throw new Error("No accounts found");
      }
      return accounts.map((account) => {
        if (!account || account.trim() === "") {
          throw new Error("Received empty or invalid account");
        }
        return getAddress(account);
      });
    },
    async getProvider() {
      if (!provider_) {
        const { ethereum } = new WalletTgSdk({ injected: true });
        provider_ = ethereum;
      }
      return provider_;
    },
    async getChainId() {
      const provider = await this.getProvider();
      if (!provider)
        throw new ProviderNotFoundError();
      return Number(await provider.request({ method: "eth_chainId" }));
    },
    async isAuthorized() {
      try {
        return false;
      } catch {
        return false;
      }
    },
    onAccountsChanged(accounts) {
      if (accounts.length === 0)
        this.onDisconnect();
      else
        config.emitter.emit("change", { accounts: accounts.map((x) => getAddress(x)) });
    },
    onChainChanged(chain) {
      const chainId = Number(chain);
      config.emitter.emit("change", { chainId });
    },
    onDisconnect() {
      config.emitter.emit("disconnect");
    },
    async switchChain({ chainId }) {
      const provider = await this.getProvider();
      if (!provider) {
        throw new ProviderNotFoundError();
      }
      const chain = config.chains.find((x) => x.id === chainId);
      console.log("==Switch ChainID is:" + chain);
      if (!chain) {
        throw new SwitchChainError(new ChainNotConfiguredError());
      }
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: numberToHex(chainId) }]
        });
        return chain;
      } catch (error) {
        if (/(user rejected)/i.test(error.message)) {
          throw new UserRejectedRequestError(error);
        }
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId: numberToHex(chainId),
            chainName: chain.name,
            rpcUrls: chain.rpcUrls.default.http,
            nativeCurrency: chain.nativeCurrency,
            blockExplorerUrls: chain.blockExplorers?.default.url ? [chain.blockExplorers.default.url] : []
          }]
        });
        return chain;
      }
    }
  }));
}
